import React, { useState } from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { RequestFormModal } from "../../components/modalform";
import { useMedia } from "use-media";
import { ComingSoonWrapper } from "../../components/common/PricingCard";
import { RatingGoTo } from "../conversational-ai-platform";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../homepage";

import {
  InfoIcon,
  PriceFreeIcon,
  PriceProIcon,
  PricestadardIcon,
  PriceEnterpriseIcon,
  YesIcon,
  NoIcon,
  UpArrowIcon,
  DropdownBlack,
} from "../../components/common/Icons";

import {
  SubscriptionPlanChange,
  PricingWrapperAssitants,
} from "../../components/common/PricingCard";
import { CookiesPoup } from "../homepage";

const bannerImage = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/header_customer_banner_mobile.svg");

const OgImage = require("../../assets/img/home_page_assist/home_header_assist.png");

const fourInOne = require("../../assets/img/home_page_assist/four_in_one.svg");

export default function PricingPage() {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(true);
  const [yearly, isYearly] = useState(false);

  const [plan, setPlan] = useState("");

  const [showThanksPage, setShowThanksPage] = useState(false);

  const [more, setMore] = useState(false);
  const [more1, setMore1] = useState(false);
  const [more2, setMore2] = useState(false);

  const isMobile = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Conversational AI Pricing | Free Conversational AI Chatbot"
        description="Learn more about conversational ai pricing plans for workativ assistant. Best value for price conversational ai chatbot solution for workplace support. "
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Conversational AI Pricing | Free Conversational AI Chatbot"
        ogImage={OgImage}
        ogDescription="Learn more about conversational ai pricing plans for workativ assistant. Best value for price conversational ai chatbot solution for workplace support. "
      />
      <Container>
        <div className="_assistant_pricing_wrapper">
          <Layout
            backgroundColor={"bg_feature _assistant_pricing"}
            logoFor="ASSISTANT"
          >
            <div>
              {/*Pricing Start*/}
              <section className="top_section pricing_page _assistant_pricing assistant_pricing_page">
                <div className="container-fluid">
                  <div className="row">
                    <div className="container">
                      <div
                        className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 ${
                          isMobile ? "pr-0 pl-0" : ""
                        }`}
                      >
                        <div className="main-slider-left pr-0 pl-0">
                          <div className="d-flex flex-column justify-content-center align-items-center mb-20">
                            <img src={fourInOne} className="four-in-one" />
                            <h4 className="font-section-sub-header text-align-center">
                              The most advanced{" "}
                              <s className="strike-through-con">single</s>{" "}
                              support automation platform
                            </h4>
                            <p className="font-section-normal-text-testimonials text-align-center">
                              Maximize productivity with one central workspace
                              that connects your bots, app workflows, knowledge
                              base, and live chat inbox.
                            </p>
                          </div>
                          {/* <p>Flexible. Scalable. Affordable.</p> */}
                          <div className="form_below_content ">
                            <span>Easy setup.</span>
                            <span>No credit card. </span>
                            <span>Onboarding included.</span>
                          </div>
                          <div className="plan-new-pricing-assistants text-align-center">
                            <h6 className="font-section-normal-text-medium">
                              Save 10% on Workativ Assistant plans by paying
                              annually.
                              <span className="font-section-small-text pricing_discounts_assitants ml-2">
                                Discounts reflected below.
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="pricing-columns pricing-section">
                  <div className="container">
                    <PricingWrapperAssitants />
                  </div>
                </section>
              </section>

              {/* Full comparision */}
              <section className="full_comaparision _assistant_pricing">
                <div className="container">
                  <h1 className="font-section-sub-header-small">
                    All prices are exclusive of applicable taxes{" "}
                  </h1>
                  <p
                    className={`font-section-normal-text-testimonials ${
                      isMobile ? "mb-0 pb-0" : ""
                    }`}
                  >
                    24 x 7 email support included with all plans
                  </p>
                  <div
                    className={
                      open
                        ? "show_button_full_comaparison open"
                        : "show_button_full_comaparison "
                    }
                  >
                    {isMobile ? null : (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setOpen(!open);
                          setTitle(!title);
                        }}
                      >
                        {" "}
                        {title
                          ? "SHOW ADDITIONAL FEATURES"
                          : "HIDE ADDITIONAL FEATURES"}
                      </button>
                    )}
                  </div>
                  <div className="table_mob_comapre">
                    <div className="table_full_comapare table-new-assistants">
                      <div className="mob_overflow_table">
                        <table className="table table-bordered table-responsive-sm">
                          <thead>
                            <tr className="tr_bg">
                              <th
                                scope="col"
                                className="font-section-normal-text-medium"
                              >
                                Features
                              </th>
                              <th
                                scope="col"
                                className="font-section-normal-text-medium"
                              >
                                Description
                              </th>
                              <th
                                scope="col"
                                className="font-section-normal-text-medium"
                              >
                                Starter
                              </th>
                              <th
                                scope="col"
                                className="font-section-normal-text-medium"
                              >
                                Professional
                              </th>
                              <th
                                scope="col"
                                className="font-section-normal-text-medium"
                              >
                                Enterprise
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="tr_bg">
                              <th
                                scope="col"
                                className="font-name-smaller-text-medium"
                              >
                                Knowledge AI GPT
                              </th>
                              <th scope="col" />
                              <th scope="col" />
                              <th scope="col" />
                              <th scope="col" />
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Knowledge Base files{" "}
                               
                              </td>
                              <td className="font-name-smaller-text">
                                Ability to sync or upload files from knowledge
                                base.
                              </td>
                              <td>
                                <NoIcon />
                              </td>
                              <td>
                                <NoIcon />
                              </td>
                              <td className="font-name-smaller-text">
                                Up to 10K files
                              </td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Knowledge Base storage{" "}
                               
                              </td>
                              <td className="font-name-smaller-text">
                                Storage limit for knowledge base files.
                              </td>
                              <td>
                                <NoIcon />
                              </td>
                              <td>
                                <NoIcon />
                              </td>
                              <td className="font-name-smaller-text">
                                Up to 3 GB
                              </td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Knowledge sources{" "}
                               
                              </td>
                              <td className="font-name-smaller-text">
                                Ability to connect Knowldge AI with external
                                knowledge base source. e.g., SharePoint, Box
                                etc.
                              </td>
                              <td>
                                <NoIcon />
                              </td>
                              <td>
                                <NoIcon />
                              </td>
                              <td className="font-name-smaller-text">
                                Up to 5
                              </td>
                            </tr>

                            <tr className="tr_bg">
                              <th
                                scope="col"
                                className="font-name-smaller-text-medium"
                              >
                                Training
                              </th>
                              <th scope="col" />
                              <th scope="col" />
                              <th scope="col" />
                              <th scope="col" />
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Assistant Training
                              </td>
                              <td className="font-name-smaller-text">
                                Train chatbot using chat sessions to improve
                                chatbot performance.
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Training Data{" "}
                              </td>
                              <td className="font-name-smaller-text">
                                Chat sessions available for training.
                              </td>
                              <td className="font-name-smaller-text">1 year</td>
                              <td className="font-name-smaller-text">1 year</td>
                              <td className="font-name-smaller-text">1 year</td>
                            </tr>

                            <tr className="tr_bg">
                              <th
                                scope="col"
                                className="font-name-smaller-text-medium"
                              >
                                Dashboard & Analytics
                              </th>
                              <th scope="col" />
                              <th scope="col" />
                              <th scope="col" />
                              <th scope="col" />
                            </tr>

                            <tr>
                              <td className="font-name-smaller-text">
                                Session Analytics{" "}
                              </td>
                              <td className="font-name-smaller-text">
                                Analyze chatbot performance.
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Workflow Analytics
                              </td>
                              <td className="font-name-smaller-text">
                                Analyze workflow execution performance.
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Live Chat Inbox
                              </td>
                              <td className="font-name-smaller-text">
                                Analyze live chats and agent performance.
                              </td>
                              <td>
                                <NoIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Data availability
                              </td>
                              <td className="font-name-smaller-text">
                                Data available for analysis.
                              </td>
                              <td className="font-name-smaller-text">1 year</td>
                              <td className="font-name-smaller-text">1 year</td>
                              <td className="font-name-smaller-text">1 year</td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Download Report
                              </td>
                              <td className="font-name-smaller-text">
                                Usage and summary data available to download.
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                            </tr>

                            <tr className="tr_bg">
                              <th
                                scope="col"
                                className="font-name-smaller-text-medium"
                              >
                                Logs
                              </th>
                              <th scope="col" />
                              <th scope="col" />
                              <th scope="col" />
                              <th scope="col" />
                            </tr>

                            <tr>
                              <td className="font-name-smaller-text">
                                Session Logs
                              </td>
                              <td className="font-name-smaller-text">
                                Session logs between chatbot and end user.
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Agent Handover Logs
                              </td>
                              <td className="font-name-smaller-text">
                                Session logs between end user and live agents.
                              </td>
                              <td>
                                <NoIcon />
                              </td>

                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                App Workflow Logs
                              </td>
                              <td className="font-name-smaller-text">
                                Workflow automation logs to verify success or
                                failed executions.
                              </td>

                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Approval Logs
                              </td>
                              <td className="font-name-smaller-text">
                                Approval history logs to track approvals.
                              </td>
                              <td>
                                <NoIcon />
                              </td>

                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Live Chat Inbox Logs
                              </td>
                              <td className="font-name-smaller-text">
                                Session logs for live chats.
                              </td>
                              <td>
                                <NoIcon />
                              </td>

                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Data availability
                              </td>
                              <td className="font-name-smaller-text">
                                Logs available for analysis.
                              </td>
                              <td className="font-name-smaller-text">1 year</td>
                              <td className="font-name-smaller-text">1 year</td>
                              <td className="font-name-smaller-text">1 year</td>
                            </tr>

                            <tr className="tr_bg">
                              <th
                                scope="col"
                                className="font-name-smaller-text-medium"
                              >
                                App Notifications
                              </th>
                              <th scope="col" />
                              <th scope="col" />
                              <th scope="col" />
                              <th scope="col" />
                            </tr>
                            <tr>
                              <td className="font-name-smaller-text">
                                Admin Notifications
                              </td>
                              <td className="font-name-smaller-text">
                                Product notifications via email and within app.
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                              <td>
                                <YesIcon />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="bud_space"></div>
                    <div className="new_pricing_coming_soon">
                      <ComingSoonWrapper className="font-section-medium-text-pricing-plan-heading cmng-soon-wrapper-pricing-assist">
                        <sup
                          style={{
                            top: "0.6em ",
                            color: "#E05959",
                            fontSize: "15px",
                          }}
                        >
                          *
                        </sup>
                        Coming soon
                      </ComingSoonWrapper>
                    </div>
                  </div>
                  {isMobile ? (
                    <div className="show_button_full_comaparison new_button_pricing_show width-100 float-left">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setOpen(!open);
                          setTitle(!title);
                        }}
                      >
                        {" "}
                        {title
                          ? "Show ADDITIONAL FEATURES"
                          : "Hide ADDITIONAL FEATURES"}
                      </button>
                    </div>
                  ) : null}
                </div>
              </section>
              {/*Pricing End*/}
            </div>

            <section className="top_section top_section_bottom pricing_page">
              <div className="container-fluid">
                <div className="row">
                  <div className="container">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="main-slider-left">
                        <h1 className="font-section-sub-header">
                          Professional Services
                        </h1>
                        <h6 className="font-section-sub-header-small">
                          Need help on building your first chatbot? or need hand
                          with additional training?
                        </h6>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          Our professional services can help you design, build,
                          configure and optimize your chatbot using our
                          platform. We also offer training to help you do it
                          yourself. Reach out to us to discuss your requirement.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="float-left" style="width: 100%;">
              <div className="container mb-5 ">
                <h5 className="text-center margin-bottom-20 font-section-normal-text-medium">
                  Join hundreds of industry leaders
                </h5>{" "}
                {isMobile ? (
                  <img loading="lazy" className="" src={bannerImageMobile} />
                ) : (
                  <img loading="lazy" className="" src={bannerImage} />
                )}
              </div>
            </div>
            <RatingGoTo />
            <Faq />
            <RequestForm isFooterForm={true} fromPage="assistant-pricing"/>
            {plan == "" ? null : (
              <RequestFormModal
                setPlan={setPlan}
                plan={plan}
                showThanksPage={showThanksPage}
                setShowThanksPage={setShowThanksPage}
              />
            )}
            {/* <GoToPopup />
            <CookiesPoup /> */}
          </Layout>
        </div>
      </Container>
    </>
  );
}

function Faq() {
  return (
    <React.Fragment>
      <div className="mob_accordian mob_accordian_faq">
        <section className="accordian_landing trial_accordian_landing">
          <div className="container">
            <h2 className="font-section-sub-header">
              Pricing Frequently Asked Questions
            </h2>
            <div className="col-12 pl-0 faq_landing">
              <Accordion
                id="accordion"
                className="accordion pl-0"
                preExpanded={["a"]}
              >
                <div className="accordion-wrapper pricing_faq">
                  {faqData.map((data) => (
                    <AccordionItem uuid={data.uuid}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{data.header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          dangerouslySetInnerHTML={{ __html: data.paragraph }}
                          className="font-section-normal-text-testimonials line-height-18"
                        />
                        {data.paragraph1 && (
                          <p
                            className="para_2_pricing font-section-normal-text-testimonials line-height-18"
                            dangerouslySetInnerHTML={{
                              __html: data.paragraph1,
                            }}
                          />
                        )}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

export const faqData = [
  {
    header: "How do we calculate a chatbot session ? ",
    paragraph:
      "A bot session is counted as 1 when an end-user initiates a conversation with the chatbot. ",
    active: "active",
    uuid: "a",
  },
  {
    header: "What is an app workflow execution?  ",
    paragraph:
      "Workflow execution happens when the chatbot executes action(s) with single or multiple apps. You can have multiple actions or steps in a single workflow execution.",
  },
  {
    header: "How do we calculate app workflow executions ?",
    paragraph:
      "An app workflow execution is calculated as 1 execution, irrespective of number of actions or steps within the workflow. For example, when chatbot unlocks a user account in Active Directory and creates a closed ticket in ServiceNow. In this workflow you have used 2 actions, but we only calculate as 1 workflow execution. If you execute the workflow 10 times, 10 workflow executions will be consumed from your available execution limit or quota depending on your subscription plan.",
  },
  {
    header: "What are additional consumptions? 							",
    paragraph:
      "Each subscription plan comes with a chatbot session and app workflow execution quota or limit. Once you exceeds this quota or limit, you will be charged $0.1 for each additional session, and each workflow execution. Please note that add-on consumption is only available from professional plan.",
  },
  {
    header: "What is on-prem connector?  ",
    paragraph:
      "Workativ’s On-prem Connector provides a secure connection between our platform (and chatbot) and your on-prem apps behind your corporate firewall. For increased level of security, workativ relays HTTPS calls to workativ agent behind your corporate firewall to execute workflows with your on-prem apps.",
  },
  {
    header: "How safe is my data?",
    paragraph:
      'We are ISO/IEC 27001:2013 compliant and we take security seriously. Our servers are hosted in world-class data centers in AWS that are protected by biometric locks and 24-hour surveillance. We ensure that our application and servers are always up to date with the latest security patches. All plans include SSL for encryption to keep your data safe. You can find more on our security measure <span><a href="/security">here</a></span>.',
  },
  {
    header: "Plan selection. How do I know which plan is right for me?							",
    paragraph:
      "To help you decide which plan is right, we suggest you consider the following: 1. Employee count. 2. Ticket volume.",
    paragraph1: `Though we do not charge by user count, we suggest that you keep user concurrency in mind. The starter plan is ideal for an employee strength in the range of 500, professional plan is ideal for users in the rage of 500 to 1000, and Enterprise in the range of 1000 or more. And secondly, higher ticket volume may require more bot sessions and active app workflow automations. We can help you decide based on your needs, so just send us an email at  <span><a href="mailto:sales@workativ.com">sales@workativ.com</a></span>.`,
  },
  {
    header: "Subscription lengths for paid plans.",

    paragraph:
      "We have both monthly and annual plans. You will need to subscribe to a monthly or annual plan of your choice to use Workativ Assistant in paid plans.							",
  },
  {
    header: "Can I change my plan anytime?",

    paragraph: "For now, you can switch to higher plans only.",
  },
  {
    header: "Payment options?  ",

    paragraph:
      'We only accept bank account transfer, wire transfer, and all major credit card payments. For more details, please contact<span><a href="mailto:sales@workativ.com">sales@workativ.com</a></span>',
  },
  {
    header: "Invoice and payments?",

    paragraph:
      "For paid plans, Invoice is raised as soon as you start using the app, post-trial period or upgrade. Payments are due within 7 days of invoice. Additional usage of app workflow executions and bot sessions are included in the next invoice. ",
  },
  {
    header: "Do we offer premium support? ",

    paragraph:
      "Premium support is available as a custom plan or in an enterprise plan. Please reach out us to discuss your requirements. ",
  },
  {
    header: "Languages supported?",

    paragraph:
      "English, and Spanish. Coming soon - French, Arabic, and German.",
  },
  {
    header: "What happens after I consume my monthly limit?",

    paragraph:
      "Your chatbot will stop responding to user messages for that billing period. For users in standard and pro plan, your bots will continue to work but charged addtional as add-on consumptions.",
  },
  {
    header:
      "Will my unused credits ( Bot sessions, and App workflow executions) carry forwarded to next month?",

    paragraph:
      "No, your monthly consumption will reset at start of every billing cycle.",
  },
  {
    header: "More questions on plans and pricing?",

    paragraph: `If you have any additional questions or clarification on pricing or subscription, please reach out to us at <span><a href="mailto:sales@workativ.com">sales@workativ.com</a></span> and we’ll be happy to help.`,
  },
];
